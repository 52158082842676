.main_card_white{
    height: fit-content;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5vh;
    padding-top: 20px;
    padding-bottom: 20px;

    .text_card_white{
        margin-top: 10px;
        height: fit-content;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        h{
            color: #2D2E83;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Vietnam';
        }

        p{
            color: #29235C;
            font-size: 14px;
            font-weight: 300;
            font-family: 'Vietnam';
        }
    }
}

@media (min-width: 768px){
    .main_card_white{
        display: none;
    }
}