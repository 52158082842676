.main_contatti{
    position: absolute;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 90px;
    margin-bottom: 30px;

    h{
        font-size: 30px;
        font-weight: 700;
        font-family: 'Vietnam';
        color: #2D2E83;
    }

    .formCustom{
        height: fit-content;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 20px;
        gap: 30px;


        .input_fullName{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 30px;
            align-items: center;
        }

        .input{
            height: 55px;
            width: 100%;
            border: none;
            background-color: #9DCBEF33;
            padding: 5px 20px;
            color: #29235C;
            font-family: 'Vietnam';
            font-size: 16px;
            font-weight: 400;
            border-radius: 8px;
        }

        p{
            font-size: 16px;
            font-weight: 400;
            font-family: 'Vietnam';
            color: #29235C;
        }

        p1{
            font-size: 16px;
            font-weight: 700;
            font-family: 'Vietnam';
            color: #F7A941;
        }

        .text-area{
            height: 255px;
            width: 100%;
            resize: none;
            border: 2px solid #096A9680;
            border-radius: 14px;
            text-align: left;
            line-height:2;
            outline: none;
        }

        .submit{
            width: 161px;
            height: 25px;
            color: #fff;
            background-color: #F7A941;
            border: none;
            border-radius: 16px;
            font-size: 12px;
            font-family: 'Vietnam';
            font-weight: 600;
            cursor: pointer;
        }
    }

    .footer_text{
        display: none;
    }

    .loader {
        border: 8px solid #f3f3f3; 
        border-top: 8px solid #F7A941; 
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (min-width: 768px){
    .main_contatti{
        position: absolute;
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 90px;
        margin-bottom: 30px;
    
        h{
            font-size: 30px;
            font-weight: 700;
            font-family: 'Vietnam';
            color: #2D2E83;
        }
    
        .formCustom{
            height: fit-content;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 20px;
            gap: 30px;
    
            .input{
                height: 55px;
                width: 100%;
                border: none;
                background-color: #9DCBEF33;
                padding: 5px 20px;
                color: #29235C;
                font-family: 'Vietnam';
                font-size: 16px;
                font-weight: 400;
                border-radius: 8px;
            }
    
            p{
                font-size: 16px;
                font-weight: 400;
                font-family: 'Vietnam';
                color: #29235C;
            }

            p1{
                font-size: 16px;
                font-weight: 700;
                font-family: 'Vietnam';
                color: #F7A941;
            }
    
            .text-area{
                height: 255px;
                width: 100%;
                resize: none;
                border: 2px solid #096A9680;
                border-radius: 14px;
                text-align: left;
                line-height:2;
                outline: none;
                font-size: 18px;
            }
    
            .submit{
                width: 200px;
                height: 40px;
                color: #fff;
                background-color: #F7A941;
                border: none;
                border-radius: 16px;
                font-size: 20px;
                font-family: 'Vietnam';
                font-weight: 600;
            }
        }
    }
}

@media (min-width: 1024px){
    .main_contatti{
        position: absolute;
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 90px;
        margin-bottom: 30px;
    
        h{
            font-size: 30px;
            font-weight: 700;
            font-family: 'Vietnam';
            color: #2D2E83;
        }
    
        .formCustom{
            height: fit-content;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 20px;
            gap: 30px;
    
            .input{
                height: 55px;
                width: 100%;
                border: none;
                background-color: #9DCBEF33;
                padding: 5px 20px;
                color: #29235C;
                font-family: 'Vietnam';
                font-size: 16px;
                font-weight: 400;
                border-radius: 8px;
            }
    
            p{
                font-size: 20px;
                font-weight: 400;
                font-family: 'Vietnam';
                color: #29235C;
            }

            p1{
                font-size: 28px;
                font-weight: 700;
                font-family: 'Vietnam';
                color: #F7A941;
            }
    
            .text-area{
                height: 255px;
                width: 100%;
                resize: none;
                border: 2px solid #096A9680;
                border-radius: 14px;
                text-align: left;
                line-height:2;
                outline: none;
                font-size: 20px;
                margin: 0 auto;
            }
    
            .submit{
                width: 250px;
                height: 40px;
                color: #fff;
                background-color: #F7A941;
                border: none;
                border-radius: 16px;
                font-size: 20px;
                font-family: 'Vietnam';
                font-weight: 600;
            }
        }

        .footer_text{
            display: flex;
            height: 30vh;
            width: 50%;
            align-items: center;  
            justify-content: center;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;

            p{
                color: #2A245D;
                font-size: 32px;
                font-weight: 700;
                font-family: 'Vietnam';
            }

            span{
                color: #F39200;
            }


            
        }
    }
}

@media (min-width: 1201px){
    .main_contatti{
        position: absolute;
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 90px;
        margin-bottom: 30px;
    
        h{
            font-size: 30px;
            font-weight: 700;
            font-family: 'Vietnam';
            color: #2D2E83;
        }
    
        .formCustom{
            height: fit-content;
            width: 90%;
            max-width: 1280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 20px;
            gap: 30px;

            .input_fullName{
                flex-direction: row;
                width: 100%;
            }
    
            .input{
                height: 55px;
                width: 100%;
                border: none;
                background-color: #9DCBEF33;
                padding: 5px 20px;
                color: #29235C;
                font-family: 'Vietnam';
                font-size: 16px;
                font-weight: 400;
                border-radius: 8px;
            }
    
            p{
                font-size: 16px;
                font-weight: 400;
                font-family: 'Vietnam';
                color: #29235C;
            }

            p1{
                font-size: 28px;
                font-weight: 700;
                font-family: 'Vietnam';
                color: #F7A941;
            }
    
            .text-area{
                height: 255px;
                width: 100%;
                resize: none;
                border: 2px solid #096A9680;
                border-radius: 14px;
                text-align: left;
                line-height:2;
                outline: none;
            }
    
            .submit{
                width: 204px;
                height: 47px;
                color: #fff;
                background-color: #F7A941;
                border: none;
                border-radius: 16px;
                font-size: 24px;
                font-family: 'Vietnam';
                font-weight: 600;
            }
        }
    }

    
}