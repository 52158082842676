.main_home{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    margin-top: 90px;

    .main_intro{
        height: fit-content;
        width: 100vw;
        background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 31.08%, #36A9E1 63.31%, rgba(54, 169, 225, 0) 79.54%);
    }

    .main_content_sep{
        height: fit-content;
        width: 100vw;
        display: flex;
        justify-content: center;
        background: linear-gradient(to bottom, white 55%, #2D2E83 45%);
    }

    .main_content{
        height: fit-content;
        width: 100vw;
        background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 5.8%, #667ECB 22.64%, #5A48B0 29.59%, #5738A8 39.5%, #304A98 56.82%, #2A245D 62%, #5738A7 78.88%);
    }
}