.main_separator_tech{
    width: 100vw;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/component/Tecnologia/Separator/BG.svg');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;

    h{
        color: #2E3085;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Vietnam';
    }
}

@media (min-width: 768px){
    .main_separator_tech{
        display: none;
    }
}