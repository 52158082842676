.header_mobile{
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .logo_mobile{
        -webkit-tap-highlight-color: transparent;
        margin: 20px;
        .logo{
            display: flex;
        }

        p{
            display: none;
        }
    }

    .conatiner_title{
        display: none;
    }

    .link{
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    .container_button{
        display: flex;
        flex-direction: column;
        margin-right: 8vw;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 100%;
        color: #ffffff;
        font-size:x-small;
        font-family: 'Vietnam';
        cursor: pointer;
        outline-color: transparent;
        transition: font-size 0.5s ease;

        .mobile{
            text-align: center;
            
            img{
                height: 30px;
                width: 30px;

            }
            p {
                margin: 0;
                font-family: 'Vietnam';
            }
        }

        .pc{
            display: none;
        }


    }
}

@media (min-width: 1024px){
    .header_mobile{
        position: fixed;
        height: 90px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        max-width: 1440px;
        .logo_mobile{
            -webkit-tap-highlight-color: transparent;
            margin: 50px;
            .logo{
                display: flex;
                align-items: center;
                gap: 20px;

                p{
                    display: inherit;
                    color: #fff;
                    font-size: 26px;
                    font-weight: 300;
                    font-family: 'Vietnam';
                }
            }
        }
    
        .link{
            -webkit-tap-highlight-color: transparent;
            text-decoration: none;
        }
    
        .container_button{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 100%;
            color: #ffffff;
            font-size:18px;
            font-family: 'Vietnam';
            cursor: pointer;
            outline-color: transparent;
    
            .image{
                display: none;
            }

            .mobile {
                display: none;
            }


            .pc{
                display: flex;
                align-items: center;
                justify-content: space-between;
                p1 {
                    display: inherit;
                    margin: 2px;
                }
                img {
                    margin: 2px;
                }
            }
        }
    }
}