.main_card{
    display: none;
}

@media (min-width: 768px){
    .main_card{
        height: fit-content;
        width: 100vw;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        padding-bottom: 50px;
        padding-top: 50px;
    
        .card{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            align-items: flex-start;
            text-align: left;
            gap: 5px;
    
            .title_single_card{
                display: flex;
                align-items: flex-start;
                gap: 10px;

                img{
                    height: 30px;
                    width: 30px;
                }
    
                h{
                    color: #2D2E83;
                    font-size: 20px;
                    font-family: 'Vietnam';
                    font-weight: 700;
                    transition: font-size 0.5s ease;
                }
            }
    
            p{
                font-size: 16px;
                color: #29235C;
                font-family: 'Vietnam';
                font-weight: 400;
                transition: font-size 0.5s ease;
            }
        }
    }
}

@media (min-width: 1024px){
    .main_card{
        height: fit-content;
        width: 100vw;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        padding-bottom: 50px;
        padding-top: 50px;
    
        .card{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            align-items: flex-start;
            text-align: left;
            gap: 5px;
    
            .title_single_card{
                display: flex;
                align-items: flex-start;
                gap: 10px;

                img{
                    height: 50px;
                    width: 50px;
                }
    
                h{
                    color: #2D2E83;
                    font-size: 28px;
                    font-family: 'Vietnam';
                    font-weight: 700;
                }
            }
    
            p{
                font-size: 22px;
                color: #29235C;
                font-family: 'Vietnam';
                font-weight: 400;
            }
        }
    }
}

@media (min-width: 1201px){
    .main_card{
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        padding-bottom: 100px;
        padding-top: 100px;
        margin-top: 100px;
        margin: 0 auto;
    
        .card{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            align-items: flex-start;
            text-align: left;
            gap: 5px;
    
            .title_single_card{
                display: flex;
                align-items: flex-start;
                gap: 10px;

                img{
                    height: 50px;
                    width: 50px;
                }
    
                h{
                    color: #2D2E83;
                    font-size: 28px;
                    font-family: 'Vietnam';
                    font-weight: 700;
                }
            }
    
            p{
                font-size: 22px;
                color: #29235C;
                font-family: 'Vietnam';
                font-weight: 400;
            }
        }
    }
}
