.main_separator{
    position: relative;
    height: fit-content;
    width: 100vw;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, white 55%, #2D2E83 45%);

    img{
        margin-bottom: 10px;
    }
}

@media (min-width: 481px){
    .main_separator{

        img{
            margin-bottom: 40px;
        }
    }
}
