.main_workflow{
    overflow-x: hidden;
    position: relative;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 5.8%, #667ECB 22.64%, #5A48B0 29.59%, #5738A8 39.5%, #304A98 56.82%, #2A245D 62%, #5738A7 78.88%);
    align-items: center;
    margin-top: -1px;

    --opacity-start: 0;
    --opacity-end: 1;
    --translate-end: 1;


    .title{
        width: 100vw;
        height: fit-content;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        h{
            color: #ffffff;
            font-size: 24px;
            font-weight: 700;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
    }

    .slowRightFast{
        opacity: var(--opacity-start);
        transform: translateX(-50px);
        animation: goRight 0.5s ease-out forwards;
    }

    .slowLeftFast{
        opacity: var(--opacity-start);
        transform: translateX(100px);
        animation: goRight 0.5s ease-out forwards;
    }

    .slowDown{
        opacity: var(--opacity-start);
        transform: translateY(-50px);
        animation: goDown 1s ease-out forwards;
        animation-delay: 0.7s;
    }
    .slowDownFast{
        opacity: var(--opacity-start);
        transform: translateY(-50px);
        animation: goDown 0.5s ease-out forwards;
    }
    

    .slowOblique{
        opacity: var(--opacity-start);
        transform: translateX(-50px) translateY(50px);
        animation: goOblique 1s ease-out forwards;
    }
    .slowObliqueDown{
        opacity: var(--opacity-start);
        transform: translateX(-50px) translateY(-50px);
        animation: goOblique 1s ease-out forwards;
        animation-delay: 0.7s;
    }

    .slowZ{
        opacity: var(--opacity-start);
        animation: goZ 1s ease-out forwards;
    }

    .first_image{
        height: fit-content;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        gap: 8vh;
        transition: gap 0.5s ease;
    }

    .text{
        height: fit-content;
        width: 90%;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;

        h{
            color: #ffffff;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        p{
            color: #ffffff;
            font-size: 14px;
            font-weight: 300;
            line-height: 18.2px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
    }

    .first_car{
        width: 90%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;
    }

    .second_car{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .mobile{
        height: fit-content;
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 80px;
        gap: 30px;
        text-align: left;

        .mobile_container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;

            h{
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Vietnam';
                transition: fontsize 0.5s ease;
            }
    
            p{
                color: #ffffff;
                font-size: 14px;
                font-weight: 300;
                line-height: 18.2px;
                font-family: 'Vietnam';
                transition: fontsize 0.5s ease;
            }
        }
    }

    .arrow{
        height: fit-content;
        width: 90%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 10px;
        position: relative;

        img {
            position:absolute;
            margin-left: 15%;
            top: 0px;
        }
    }

    .hardware{
        height: fit-content;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-top: 115px;

        h{
            color: #ffffff;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        p{
            color: #ffffff;
            font-size: 14px;
            font-weight: 300;
            line-height: 18.2px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
    }

    .double_image{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 20px;

        .image1{
            position:relative;
            margin-top: 210px;
        }
    }



    .firma {
        height: fit-content;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 40px;

        .firma_container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 50%;

            h{
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Vietnam';
                transition: font-size 0.5s ease;
            }
    
            p{
                color: #ffffff;
                font-size: 14px;
                font-weight: 300;
                font-family: 'Vietnam';
                transition: font-size 0.5s ease;
            }
        }
    }

    .vantaggi{
        height:fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        h{
            color: #ffffff;
            font-size: 24px;
            font-weight: 700;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        .carat_vantaggi{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 30px;

            .card{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                /* img {
                    height: 48px;
                    width: 48px;
                } */
    
                p{
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                    transition: 0.5s ease;
                }
            }
        }

    }
    
    .mappa{
        margin-top: 50px;
        width: 90%;
        height: 25vh;
        background-image: url('../../../assets/component/Home/Workflow/mappa.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .contatti{
        height: 40vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        background-image: url('../../../assets/component/Home/Workflow/BG_last_mobile.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        
        h{
            font-size: 24px;
            color: #FFFFFF;
            font-weight: 700;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        .contatti_button{
            text-decoration: none;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 700;
            font-family: 'Vietnam';
            background-color: #F7A941;
            padding: 10px 20px;
            border-radius: 16px; 
            -webkit-tap-highlight-color: transparent;
            transition: font-size 0.5s ease;
        }

        .contatti_button.slowUpFast{
            opacity: var(--opacity-start);
            transform: translateY(50px);
            animation: goDown 0.5s ease-out forwards;
        }
    }
}

@keyframes goRight {
    to {
        opacity: var(--opacity-end);
        transform: translateX(var(--translate-end));
    }
}

@keyframes goDown {
    to {
        opacity: var(--opacity-end);
        transform: translateY(var(--translate-end));
    }
}

@keyframes goUp {
    to {
        opacity: var(--opacity-end);
        transform: translateY(var(--translate-end));
    }
}

@keyframes goOblique {
    to {
        opacity: var(--opacity-end);
        transform: translateX(var(--translate-end)) translateY(var(--translate-end));
    }
}

@keyframes goZ {
    to {
        opacity: var(--opacity-end);
        transform: translateZ(100px);
    }
}   


.main_workflow_pc{
    display: none;
}


@media (min-width: 768px){
    .main_workflow{
        position: relative;
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 5.8%, #667ECB 22.64%, #5A48B0 29.59%, #5738A8 39.5%, #304A98 56.82%, #2A245D 62%, #5738A7 78.88%);
        align-items: center;
        margin-top: -1px;
    
        .title{
            width: 100vw;
            height: fit-content;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
    
            h{
                color: #ffffff;
                font-size: 36px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
        }
    
        .first_image{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            gap: 20vh;

            img {
                height: 238px;
                width: 184px;
            }
        }
    
        .text{
            height: fit-content;
            width: 90%;
            align-items: center;
            justify-content: flex-start;
            margin-top: 30px;
    
            h{
                color: #ffffff;
                font-size: 24px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 20px;
                font-weight: 300;
                line-height: 22.2px;
                font-family: 'Vietnam';
            }
        }
    
        .first_car{
            width: 90%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 40px;

            img {
                height: 261px;
                width: 343px;
            }
        }
    
        .second_car{
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            img {
                height: 261px;
                width: 343px;
            }
        }
    
        .mobile{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 80px;
            gap: 30px;
            text-align: left;

            img{
                height: 476px;
                width: 222px;
            }
    
            .mobile_container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                gap: 5px;
    
                h{
                    color: #ffffff;
                    font-size: 28px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                }
        
                p{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 22.2px;
                    font-family: 'Vietnam';
                }
            }
        }
    
        .arrow{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 10px;
            position: relative;
            img {
                position:absolute;
                margin-left: 10%;
                top: -150px;
                height: 255px;
                width: 203px;
            }
        }
    
        .hardware{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 40px;
            margin-top: 80px;

            img {
                height: 100.66px;
                width: 253.95px;
            }
    
            h{
                color: #ffffff;
                font-size: 24px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 20px;
                font-weight: 300;
                line-height: 22.2px;
                font-family: 'Vietnam';
            }
        }
    
        .double_image{
            height: fit-content;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 50px;
    
            .image1{
                position:relative;
                margin-top: 400px;
                height: 351.17px;
                width: 412.67px;
            }

            img{
                height: 486.08px;
                width: 226.18px;
            }
    
        }
    
        .firma {
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 40px;
    
            .firma_container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 50%;
    
                h{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                }
        
                p{
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 300;
                    font-family: 'Vietnam';
                    line-height: 22.2px;
                }
            }

            img{
                height: 204.9px;
                width: 220.56px;
            }
        }
    
        .vantaggi{
            height:fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
    
            h{
                color: #ffffff;
                font-size: 36px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .carat_vantaggi{
                height: fit-content;
                width: 90%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-top: 30px;
    
                .card{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    max-width: 20%;
    
                    img {
                        height: 48px;
                        width: 48px;
                    }
        
                    p{
                        color: #FFFFFF;
                        font-size: 18px;
                        font-weight: 700;
                        font-family: 'Vietnam';
                    }
                }
            }
    
        }
        
        .mappa{
            margin-top: 50px;
            width: 90%;
            height: 40vh;
            background-image: url('../../../assets/component/Home/Workflow/mappa.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    
        .contatti{
            height:50vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
            background-image: url('../../../assets/component/Home/Workflow/BG_last_tablet.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            
            h{
                font-size: 30px;
                color: #FFFFFF;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .contatti_button{
                text-decoration: none;
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 700;
                font-family: 'Vietnam';
                background-color: #F7A941;
                padding: 10px 20px;
                border-radius: 16px; 
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}

@media (min-width: 1024px){
    .main_workflow{
        position: relative;
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 5.8%, #667ECB 22.64%, #5A48B0 29.59%, #5738A8 39.5%, #304A98 56.82%, #2A245D 62%, #5738A7 78.88%);
        align-items: center;
        margin-top: -1px;
    
        .title{
            width: 100vw;
            height: fit-content;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
    
            h{
                color: #ffffff;
                font-size: 50px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
        }
    
        .first_image{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            gap: 25vh;

            img{
                height: 238.08px;
                width: 184.51px;
            }

        }
    
        .text{
            height: fit-content;
            width: 90%;
            align-items: center;
            justify-content: flex-start;
            margin-top: 70px;
    
            h{
                color: #ffffff;
                font-size: 30px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 28px;
                font-weight: 300;
                line-height: 30.2px;
                font-family: 'Vietnam';
            }
        }
    
        .first_car{
            width: 90%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 40px;

            img{
                width: 343.23px;
                height: 261.89px;
            }
        }
    
        .second_car{
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            img{
                width: 343.23px;
                height: 261.89px;
            }
        }
    
        .mobile{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 80px;
            gap: 30px;
            text-align: left;
    
            .mobile_container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                gap: 5px;
    
                h{
                    color: #ffffff;
                    font-size: 32px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                }
        
                p{
                    color: #ffffff;
                    font-size: 28px;
                    font-weight: 300;
                    line-height: 30.2px;
                    font-family: 'Vietnam';
                }
            }
        }
    
        .arrow{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 10px;
            position: relative;
            img {
                position:absolute;
                margin-left: 15%;
                top: -100px;
            }
        }
    
        .hardware{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            margin-top: 125px;
    
            h{
                color: #ffffff;
                font-size: 32px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 28px;
                font-weight: 300;
                line-height: 32.2px;
                font-family: 'Vietnam';
            }
        }
    
        .double_image{
            height: fit-content;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 50px;;
    
            .image1{
                position:relative;
                margin-top: 210px;
            }
    
        }
    
        .firma {
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 80px;
    
            .firma_container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 50%;
    
                h{
                    color: #ffffff;
                    font-size:32px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                }
        
                p{
                    color: #ffffff;
                    font-size: 28px;
                    font-weight: 300;
                    font-family: 'Vietnam';
                    line-height: 32.2px;
                }
            }
        }
    
        .vantaggi{
            height:fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 60px;
    
            h{
                color: #ffffff;
                font-size: 36px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .carat_vantaggi{
                height: fit-content;
                width: 90%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-top: 30px;
    
                .card{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    max-width: 20%;
    
                    img {
                        height: 64px;
                        width: 64px;
                    }
        
                    p{
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: 700;
                        font-family: 'Vietnam';
                    }
                }
            }
    
        }
        
        .mappa{
            margin-top: 50px;
            width: 90%;
            height: 40vh;
            background-image: url('../../../assets/component/Home/Workflow/mappa.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    
        .contatti{
            height: 80vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
            background-image: url('../../../assets/component/Home/Workflow/BG_last_tablet.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            
            h{
                font-size: 38px;
                color: #FFFFFF;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .contatti_button{
                text-decoration: none;
                color: #FFFFFF;
                font-size: 28px;
                font-weight: 700;
                font-family: 'Vietnam';
                background-color: #F7A941;
                padding: 10px 20px;
                border-radius: 16px; 
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}

@media (min-width: 1280px){
    .main_workflow{
        display: none;
    }

    .main_workflow_pc{

        --opacity-start: 0;
        --opacity-end: 1;
        --translate-end: 1;

        overflow-x: hidden;
        display: flex;
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        position: relative;
        flex-direction: column;
        background: linear-gradient(180deg, #2D2E83 -0.13%, #2D3286 5.8%, #667ECB 22.64%, #5A48B0 29.59%, #5738A8 39.5%, #304A98 56.82%, #2A245D 62%, #5738A7 78.88%);
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .title_pc{
            width: 100vw;
            height: fit-content;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            h{
                color: #fff;
                font-size: 60px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
        }


        .intro_pc{
            display: flex;
            height: fit-content;
            width: 90%;
            align-items: flex-start;
            justify-content: center;
            margin-top: 50px;
            gap: 100px;

            h1{
                font-size: 42px;
                color: #fff;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                color: #ffffff;
                font-size: 28px;
                font-family: 'Vietnam';
                font-weight: 400;
            }
        }

        .slowRight{
            opacity: var(--opacity-start);
            transform: translateX(-100px);
            animation: goRight 0.5s ease-out forwards;
        }

        .slowDown{
            opacity: var(--opacity-start);
            transform: translateY(-100px);
            animation: goDown 1s ease-out forwards;
            animation-delay: 0.7s;
        }

        .slowDownSpeed{
            opacity: var(--opacity-start);
            transform: translateY(-100px);
            animation: goDown 0.5s ease-out forwards;
        }

        .slowLeftSpeed{
            opacity: var(--opacity-start);
            transform: translateX(100px);
            animation: goRight 0.5s ease-out forwards;
        }

        .slowOblique{
            opacity: var(--opacity-start);
            transform: translateX(-100px) translateY(100px);
            animation: goOblique 1s ease-out forwards;
        }

        .slowObliqueDown{
            opacity: var(--opacity-start);
            transform: translateX(-100px) translateY(-100px);
            animation: goOblique 1s ease-out forwards;
            animation-delay: 0.7s;
        }


        .intro2_pc{
            display: flex;
            height: fit-content;
            width: 90%;
            align-items: flex-start;
            justify-content: center;
            gap: 100px;
            margin-top: 200px;

            h1{
                font-size: 42px;
                color: #fff;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                color: #ffffff;
                font-size: 28px;
                font-family: 'Vietnam';
                font-weight: 400;
            }

            img{
                height: 417px;
                width: 651px;
            }
        }

        .mobile_pc{
            display: flex;
            height: fit-content;
            width: 90%;
            gap: 50px;
            align-items: center;
            justify-content: center;
            margin-top: 120px;

            .mobile_text_pc{
                max-width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                h{
                    color: #fff;
                    font-size: 42px;
                    font-family: 'Vietnam';
                    font-weight: 700;
                }

                p{
                    color: #fff;
                    font-size: 28px;
                    font-weight: 400;
                    font-family: 'Vietnam';
                    line-height: 36px;
                }
                span{
                    text-decoration: underline 2px solid #fff;
                }
            }

            .mobile_image_pc{
                display: flex;
                max-width: 50%;
                height: fit-content;
                gap: 50px;
                align-items: flex-end;
                justify-content: center;
                opacity: var(--opacity-end);

                img{
                    height: 523.28px;
                    width: 254px;
                }
            }

            .mobile_image_pc.slowZ{
                opacity: var(--opacity-start);
                animation: goZ 1s ease-out forwards;
            }
        }

        .arrow{
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 10px;
            position: relative;
            img {
                position:absolute;
                margin-left: 220px;
                top: -80px;
                height: 232px;
                width: 289px
            }
        }

        .hardware{
            height: fit-content;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            margin-top: 115px;
    
            h{
                color: #ffffff;
                font-size: 42px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 28px;
                font-weight: 300;
                line-height: 36.4px;
                font-family: 'Vietnam';
            }
        }
        .image_hardware_pc{
            width: 90%;
            height: fit-content;
            display: flex;
            gap: 200px;
            align-items: flex-start;
            justify-content: center;
            margin-top: 80px;
        }

        .mappa_pc{
            width: 60%;
            height: fit-content;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .firma_pc {
            height: fit-content;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 100px;
            margin-top: 40px;
            
    
            .firma_container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 45%;
    
                h{
                    color: #ffffff;
                    font-size: 42px;
                    font-weight: 700;
                    font-family: 'Vietnam';
                }
        
                p{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 300;
                    font-family: 'Vietnam';
                }
            }
        }

        

        .vantaggi_pc{
            height:fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
    
            h{
                color: #ffffff;
                font-size: 60px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .carat_vantaggi_pc{
                height: fit-content;
                width: 90%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-top: 30px;
                gap: 100px;
    
                .card_pc{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    gap: 30px;
        
                    p{
                        color: #FFFFFF;
                        font-size: 28px;
                        font-weight: 700;
                        font-family: 'Vietnam';
                    }
                }
            }
    
        }

        .mappa{
            margin-top: 100px;
            width: 90%;
            height: 50vh;
            background-image: url('../../../assets/component/Home/Workflow/mappa.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .contatti{
            height: 100vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
            background-image: url('../../../assets/component/Home/Workflow/BG_last_desktop.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            
            h{
                font-size: 64px;
                color: #FFFFFF;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .contatti_button{
                text-decoration: none;
                color: #FFFFFF;
                font-size: 20px;
                font-weight:700;
                font-family: 'Vietnam';
                background-color: #F7A941;
                padding: 10px 20px;
                border-radius: 16px; 
                -webkit-tap-highlight-color: transparent;
                opacity: var(--opacity-start)
            }

            .contatti_button.slowUp{
                opacity: var(--opacity-start);
                transform: translateY(100px);
                animation: goUp 0.5s ease-out forwards;
            }
        }
    }

    @keyframes goRight {
        to {
            opacity: var(--opacity-end);
            transform: translateX(var(--translate-end));
        }
    }

    @keyframes goDown {
        to {
            opacity: var(--opacity-end);
            transform: translateY(var(--translate-end));
        }
    }

    @keyframes goUp {
        to {
            opacity: var(--opacity-end);
            transform: translateY(var(--translate-end));
        }
    }

    @keyframes goOblique {
        to {
            opacity: var(--opacity-end);
            transform: translateX(var(--translate-end)) translateY(var(--translate-end));
        }
    }

    @keyframes goZ {
        to {
            opacity: var(--opacity-end);
            transform: translateZ(100px);
        }
    }
}