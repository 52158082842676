.footer{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #2E3588 0%, #315AA5 100%);

    .footer_title{
        margin-top: 20px;

        img{
            transition: height 0.5s ease;
            transition: width 0.5s ease;
        }
    }

    .footer_background{
        display: inherit;
        margin-top: 30px;
        background-image: url('../../assets/component/Footer/BG.svg');
        background-repeat: no-repeat;
        background-size: cover;

        .footer_info_container{
            width: 80vw;
            height: fit-content;
            display: inherit;
            flex-direction: row;
            align-items: flex-end;
            margin-bottom: 70px;
            margin-top: 10px;
            
            .footer_info_column{
                width: 90%;
                height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;
    
                .info_footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 5px;
    
                    h{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    p{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    span{
                        text-decoration: underline 1px solid;
                        font-family: 'Vietnam';
                    }
                }
            }

            .footer_container_social{
                display: inherit;
                flex-direction: column;
                gap: 20px;
            }   
        }
    }
    .footer_background_pc{
        display: none;
    }
}

@media (min-width: 1024px){
    .footer{
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #2E3588 0%, #315AA5 100%);
    
        .footer_title{
            margin-top: 20px;

            img {
                height: 70px;
                width: 239px;
            }
        }
    
        .footer_background{
            display: none;
        }

        .footer_background_pc{
            display: flex;
            height: fit-content;
            width: 80%;
            align-items: flex-start;
            justify-content: center;
            gap: 50px;
            margin-bottom: 60px;

            background-image: url('../../assets/component/Footer/BG.svg');
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;

            .left_footer{
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                gap: 50px;

                .info_footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
    
                    h{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    p{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    span{
                        text-decoration: underline 1px solid;
                        font-family: 'Vietnam';
                    }
                }

                .footer_container_social{
                    display: inherit;
                    flex-direction: row;
                    gap: 20px;

                    img {
                        height: 38px;
                        width: 38px;
                    }
                }
            }

            .right_footer{
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                gap: 50px;

                .info_footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
    
                    h{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    p{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    span{
                        text-decoration: underline 1px solid;
                        font-family: 'Vietnam';
                    }
                }

                .footer_container_social{
                    display: inherit;
                    flex-direction: row;
                    gap: 20px;

                    img {
                        height: 38px;
                        width: 38px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px){
    .footer{
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #2E3588 0%, #315AA5 100%);
    
        .footer_title{
            margin-top: 20px;

            img {
                height: 70px;
                width: 239px;
            }
        }
    
        .footer_background{
            display: none;
        }

        .footer_background_pc{
            display: flex;
            height: fit-content;
            width: 80%;
            align-items: flex-start;
            justify-content: center;
            gap: 200px;
            margin-bottom: 60px;

            background-image: url('../../assets/component/Footer/BG.svg');
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;

            .left_footer{
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                gap: 50px;

                .info_footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
                    cursor: default;
    
                    h{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    p{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: 300;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    span{
                        text-decoration: underline 1px solid;
                        font-family: 'Vietnam';
                    }
                }

                .footer_container_social{
                    display: inherit;
                    flex-direction: row;
                    gap: 20px;

                    img {
                        height: 38px;
                        width: 38px;
                    }
                }
            }

            .right_footer{
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                gap: 50px;

                .info_footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
                    cursor: default;
    
                    h{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    p{
                        margin: 0;
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: 300;
                        line-height: 15px;
                        font-family: 'Vietnam';
                    }
                    span{
                        text-decoration: underline 1px solid;
                        font-family: 'Vietnam';
                    }
                }

                .footer_container_social{
                    display: inherit;
                    flex-direction: row;
                    gap: 20px;

                    img {
                        height: 38px;
                        width: 38px;
                    }
                }
            }
        }
    }
}