.right{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #fff 60%, #315BA5 40%);
    gap: 30px;

    h{
        color: #2D2E83;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Vietnam';
    }

    p{
        color: #29235C;
        font-size: 16px;
        font-weight: 300;
        line-height: 20.8px;
        font-family: 'Vietnam';
    }
}

.left{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg, #fff 60%, #315BA5 40%);
    gap: 30px;

    h{
        color: #2D2E83;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Vietnam';
    }

    p{
        color: #29235C;
        font-size: 16px;
        font-weight: 300;
        line-height: 20.8px;
        font-family: 'Vietnam';
    }
}

.white{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    h{
        color: #2D2E83;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Vietnam';
    }

    p{
        color: #29235C;
        font-size: 16px;
        font-weight: 300;
        line-height: 20.8px;
        font-family: 'Vietnam';
    }
}

.text_carat{
    display: inline;
    max-width: 100%;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 130%;
}

.left_pc{
    display: none;
}
.right_pc{
    display: none;
}
.white_pc{
    display: none;
}

@media (min-width: 768px){
    .right{
        display: none;
    }
    .left{
        display: none;
    }

    .text_carat{
        display: none;
    }
    .white{
        display:none;
    }

    .left_pc{
        height: fit-content;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin-top: 0 auto;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 45%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 24px;
                font-family: 'Vietnam';
                font-weight: 700;
                transition: font-size 0.5s ease;
            }

            p{
                color:#29235C;
                font-size: 16px;
                font-family: 'Vietnam';
                font-weight: 400;
                transition: font-size 0.5s ease;
            }
        }


    }
    .right_pc{
        height: fit-content;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin: 0 auto;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 24px;
                font-family: 'Vietnam';
                font-weight: 700;
                transition: font-size 0.5s ease;
            }

            p{
                color:#29235C;
                font-size: 16px;
                font-family: 'Vietnam';
                font-weight: 400;
                transition: font-size 0.5s ease;
            }
        }
    }
    .white_pc{
        height: fit-content;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        gap: 30px;
        margin-top: 80px;
        margin-bottom: 50px;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 45%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 24px;
                font-family: 'Vietnam';
                font-weight: 700;
                transition: font-size 0.5s ease;
            }

            p{
                color:#29235C;
                font-size: 16px;
                font-family: 'Vietnam';
                font-weight: 400;
                transition: font-size 0.5s ease;
            }
        }
    }
}

@media (min-width: 1025px){

    .background_pc_left{
        height: fit-content; 
        width: 100%;
        background: linear-gradient(270deg, #fff 60%, #315BA5 40%); 
        margin-top: 80px;
    }
    
    .left_pc{
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;
        margin: 0 auto;
        padding-bottom: 20px;
        padding-top: 20px;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 28px;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                color:#29235C;
                font-size: 20px;
                font-family: 'Vietnam';
                font-weight: 400;
            }
        }
        img{
            width: 520px;
            height: 502px;
        }
    }

    .background_pc_right{
        height: fit-content; 
        width: 100vw;
        background: linear-gradient(90deg, #fff 60%, #315BA5 40%);
        margin-top: 80px;
    }

    .right_pc{
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;
        margin: 0 auto;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 28px;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                color:#29235C;
                font-size: 20px;
                font-family: 'Vietnam';
                font-weight: 400;
            }

        }

        img{
            width: 520px;
            height: 502px;
        }
    }
    .white_pc{
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        gap: 100px;
        margin-top: 100px;
        margin-bottom: 80px;

        .text_carat_pc{
            display: flex;
            flex-direction: column;
            max-width: 40%;
            gap: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            h{
                color:#2D2E83;
                font-size: 28px;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                color:#29235C;
                font-size: 20px;
                font-family: 'Vietnam';
                font-weight: 400;
            }
        }

        img{
            width: 520px;
            height: 502px;
        }
    }
}