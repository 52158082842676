.main_title_tech{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 91px;
    margin-bottom: 30px;
    gap: 0px;

    .text_tech{
        height: fit-content;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 10vh;
        
        h{
            color: #2D2E83;
            font-size: 30px;
            font-weight: 700;
            font-family: 'Vietnam';
            line-height: 39px;
        }
    }

    .paragraph_tech{
        height: fit-content;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        margin-top: 50px;
        p{
            color: #29235C;
            font-size: 14px;
            font-weight: 400;
            font-family: 'Vietnam';
        }
        span{
            color: #29235C;
            font-size: 14px;
            font-weight: 700;
            font-family: 'Vietnam';
        }
    }

    .text_tech_pc{
        display: none;
    }

    .image_pc {
        display: none;
    }
}

@media(min-width: 768px){
    .main_title_tech{
        height: fit-content;
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 91px;
        margin-bottom: 30px;

        gap: 0px;

        .text_tech{
            display: none;
        }

        .paragraph_tech{
            display: none;
        }

        .text_tech_pc{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 55%;
            gap: 20px;
            margin-top: 50px;

            h{
                font-size: 30px;
                color: #2D2E83;
                font-family: 'Vietnam';
                font-weight: 700;
                transition: font-size 0.5s ease;
            }

            p{
                font-size: 18px;
                color: #29235C;
                font-weight: 400;
                font-family: 'Vietnam';
                line-height: 22.89px;
                transition: font-size 0.5s ease;
            }
            span{
                font-weight: 700;
            }
        }

        .image_pc{
            display: inline;
            height: 150px;
            width: 300px;
            background-image: url('../../../assets/component/Tecnologia/Title/Title.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

@media (min-width: 1024px){
    .main_title_tech{
        height: fit-content;
        width: 100vw;
        max-width: 1440px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 91px;
        margin-bottom: 10px;

        gap: 0px;

        .text_tech{
            display: none;
        }

        .paragraph_tech{
            display: none;
        }

        .text_tech_pc{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 55%;
            gap: 20px;
            margin-top: 80px;

            h{
                font-size: 40px;
                color: #2D2E83;
                font-family: 'Vietnam';
                font-weight: 700;
            }

            p{
                font-size: 22px;
                color: #29235C;
                font-weight: 400;
                font-family: 'Vietnam';
                line-height: 22.89px;
            }
            span{
                font-weight: 700;
            }
        }

        .image_pc{
            display: inline;
            height: 200px;
            width: 350px;
            background-image: url('../../../assets/component/Tecnologia/Title/Title.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}