.main_caratteristiche{
    position: relative;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;

    .container_carat{
        width: 45vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        img{
            height: 53px;
            width: 58px;
            transition: height 0.5s ease;
            transition: width 0.5s ease;
        }

        h{
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            margin-top: 10px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        p{
            color: #000000;
            font-size: 14px;
            font-weight: 300;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
    }
}

.video_container{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    h1{
        color: #29235C;
        font-size: 36px;
        font-weight: 700;
        font-family: 'Vietnam';
        margin: 20px;
        display: none;
    }

    .video{
        height: fit-content;
        max-height: 23vh;
        width: fit-content;
        max-width: 90vw;
        border-radius: 8px;
    }
}

.main_caratteristiche_pc{
    display: none;
}

@media (min-width: 768px){
    .main_caratteristiche{
        position: relative;
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        margin-bottom: 40px;
    
        .container_carat{
            width: 45vw;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
    
            img{
                height: 60px;
                width: 65px;
            }
    
            h{
                color: #000000;
                font-size: 20px;
                font-weight: 700;
                margin-top: 10px;
                font-family: 'Vietnam';
            }
    
            p{
                color: #000000;
                font-size: 18px;
                font-weight: 300;
                font-family: 'Vietnam';
            }
        }
    }

    .video_container{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    
        h1{
            color: #29235C;
            font-size: 36px;
            font-weight: 700;
            font-family: 'Vietnam';
            margin: 20px;
            display: none;
        }
    
        .video{
            height: fit-content;
            max-height: 30vh;
            width: fit-content;
            max-width: 90vw;
            border-radius: 8px;
        }
    }
}

@media (min-width: 1024px){
    .main_caratteristiche{
        position: relative;
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        margin-bottom: 40px;
    
        .container_carat{
            width: 45vw;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
    
            img{
                height: 64px;
                width: 69px;
            }
    
            h{
                color: #000000;
                font-size: 24px;
                font-weight: 700;
                margin-top: 10px;
                font-family: 'Vietnam';
            }
    
            p{
                color: #000000;
                font-size: 22px;
                font-weight: 300;
                font-family: 'Vietnam';
            }
        }
    }
}  


@media (min-width: 1201px){
    .main_caratteristiche{
        display: none;
    }

    .main_caratteristiche_pc{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;

        .title_caratteristiche_pc{
            height: fit-content;
            width: 40%;
            display: flex;
            justify-content: center;
            text-align: center;

            h1{
                color: #29235C;
                font-size: 36px;
                font-weight: 700;
                font-family: 'Vietnam';
            }
        }

        .container_caratteristiche_pc{
            height: fit-content;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 80px;
            gap: 15vh;

            .carat_pc{
                height: fit-content;
                max-width: 210px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                p{
                    color: #29235C;
                    font-size: 24px;
                    font-family: 'Vietnam';
                    font-weight: 700;
                }

                p1{
                    color: #29235C;
                    font-size: 15px;
                    font-family: 'Vietnam';
                    font-weight: 300;
                }

            }
        }
    }

    .video_container{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    
        h1{
            display: inline;
            color: #29235C;
            font-size: 36px;
            font-weight: 700;
            font-family: 'Vietnam';
            margin: 20px;
        }

        .video{
            height: fit-content;
            max-height: 50vh;
            width: fit-content;
            max-width: 90vw;
            border-radius: 8px;
        }
    }
}
