.main_title_mobile{
    height:fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    
    .text_mobile{
        height: fit-content;
        width: 70%;
        text-align: center;
        display: flex;
        flex-direction: column;

        h1{
            color: #ffffff;
            font-size: 32px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }

        p{
            color: #ffffff;
            font-size: 16px;
            font-weight: 300;
            margin: 10px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
        span{
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
            font-family: 'Vietnam';
            transition: font-size 0.5s ease;
        }
    }

    .BG_mobile{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.main_title{
    display: none;
}

@media (min-width: 768px){
    .main_title_mobile{
        height:fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
        
        .text_mobile{
            height: fit-content;
            width: 70%;
            text-align: center;
            display: flex;
            flex-direction: column;
    
            h1{
                color: #ffffff;
                font-size: 40px;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 22px;
                font-weight: 300;
                margin: 10px;
                font-family: 'Vietnam';
            }
            span{
                color: #ffffff;
                font-weight: 700;
                font-size: 22px;
                font-family: 'Vietnam';
            }
        }
    
        .BG_mobile{
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

@media (min-width: 1024px){
    .main_title_mobile{
        height:fit-content;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
        
        .text_mobile{
            height: fit-content;
            width: 70%;
            text-align: center;
            display: flex;
            flex-direction: column;
    
            h1{
                color: #ffffff;
                font-size: 50px;
                font-family: 'Vietnam';
            }
    
            p{
                color: #ffffff;
                font-size: 30px;
                font-weight: 300;
                margin: 10px;
                font-family: 'Vietnam';
            }
            span{
                color: #ffffff;
                font-weight: 700;
                font-size: 30px;
                font-family: 'Vietnam';
            }
        }
    
        .BG_mobile{
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

@media (min-width: 1201px){
    .main_title_mobile{
        display: none;
    }

    .main_title{
        display: flex;
        height: fit-content;
        width: 90vw;
        margin: 50px auto;
        align-items: flex-start;
        justify-content: center;
        max-width: 1440px;

        .text{
            h1{
                font-size: 60px;
                font-weight: 700;
                font-family: 'Vietnam';
                color: #ffffff;
            }
            p{
                font-size: 20px;
                font-weight: 400;
                font-family: 'Vietnam';
                color: #ffffff;
            }

            button{
                margin-top: 30px;
                height: 58px;
                width: 244px;
                border: none;
                background-color: #F39200;
                border-radius: 63px;
                color:#ffffff;
                font-size: 24px;
                font-weight: 700;
                font-family: 'Vietnam';
                cursor: pointer;
            }
        }
    }
}