.main_tecnologia{
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contatti_tech{
        height: 20vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;

        h{
            font-size: 24px;
            color: #2A245D;
            font-weight: 700;
            font-family: 'Vietnam';
        }

        .contatti_button{
            text-decoration: none;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 700;
            font-family: 'Vietnam';
            background-color: #F7A941;
            padding: 10px 20px;
            border-radius: 16px; 
            -webkit-tap-highlight-color: transparent;
        }
    }
}

@media (min-width: 768px){
    .main_tecnologia{
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .contatti_tech{
            height: 30vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
    
            h{
                font-size: 38px;
                color: #2A245D;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .contatti_button{
                text-decoration: none;
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Vietnam';
                background-color: #F7A941;
                padding: 10px 20px;
                border-radius: 16px; 
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}

@media (min-width: 1024px){
    .main_tecnologia{
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .contatti_tech{
            height: 30vh;
            width: 100%;
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;
            margin-bottom: 50px;
    
            h{
                font-size: 38px;
                color: #2A245D;
                font-weight: 700;
                font-family: 'Vietnam';
            }
    
            .contatti_button{
                text-decoration: none;
                color: #FFFFFF;
                font-size: 30px;
                font-weight: 700;
                font-family: 'Vietnam';
                background-color: #F7A941;
                padding: 10px 20px;
                border-radius: 16px; 
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}